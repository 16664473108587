<style scoped>
    @import url("../../styles/contadorAguaPage/painelExpande.scss");
</style>

<template>
    <div class="box_painelExpande mt-4">

        <div class="painelCardExpansor">
            <!--<div class="tit_faq">FAQ</div>-->
        
            <v-expansion-panels accordion class="cardExpansor">
                <v-expansion-panel v-for="(item,i) in liContadores" :key="i" class="el_cardExpansor mt-1">

                    <v-expansion-panel-header class="titCardExpansor">
                        {{ item.classe }}
                    </v-expansion-panel-header>

                    <!-- Adaptação resposta do card de expansão -->
                    <v-expansion-panel-content class="respCardExpansor">
                        
                        <div @click="callContador(c)"
                        class="boxContador" 
                        v-for="(c,j) in item.liContadores" :key="j"
                        >

                            <div class="elCol_Box">
                                <span class="">ID</span>
                                <span :style="{'color':'rgb(247,215,46)'}">{{ c.id }}</span>
                            </div>
                            <div class="elCol_Box">
                                <span class="">Nome</span>
                                <span :style="{'color':'rgb(247,215,46)'}">{{ c.nome }}</span>
                            </div>
                            <div class="elCol_Box">
                                <span class="">Classe</span>
                                <span :style="{'color':'rgb(247,215,46)'}">{{ item.classe }}</span>
                            </div>
                            <div class="elCol_Box">
                                <span class="">Última leitura</span>
                                <span v-if="c.lileituras.length > 0"
                                :style="{'color':'rgb(247,215,46)'}"
                                >
                                    {{ c.lileituras[c.lileituras.length-1].leitura }}
                                </span>
                                <span :style="{'color':'rgb(247,215,46)'}" v-else>Nenhuma leitura.</span>
                            </div>
                        </div>
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </div>

    </div>
</template>

<script>
export default {

    props:['liContadores'],

    data() {
        return {
            //
        }
    },
    methods: {
        //
        callContador(contador) { this.$emit('contadorSelected',contador) },
    },

    created() {
        //window.scrollTo(0,0)
        //console.log('liContadores: ',this.liContadores)
    }
}
</script>