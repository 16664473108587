<style scoped>
    @import url("../../styles/contadorAguaPage/verTodos.scss");
</style>

<template>
    <div class="box_verTodos">

        <div class="titVerTodos" v-if="!flagContadorOne">
            <v-icon large :style="{'color':'rgb(31,122,177)'}">mdi-water-pump</v-icon>
            <span class="txtTitVerTodos">Todos os contadores</span>
        </div>

        <div class="infoVerTodos" v-if="!flagContadorOne">
            <div class="linhaInfo">
                <span>Classes:</span>
                <span class="ml-1" :style="{'color':'rgb(31,122,177)'}">{{ qntClasses }}</span>
            </div>
            
            <div class="linhaInfo">
                <span>Contadores:</span>
                <span class="ml-1" :style="{'color':'rgb(31,122,177)'}">{{ qntContadores }}</span>
            </div>
        </div>

        <!-- Exibição de todos os contadores -->
        <PainelExpande v-if="!flagContadorOne"
        @contadorSelected="contadorSelected($event)" 
        :liContadores="$store.state.liContadores" 
        />

        <!-- Contador selecionado -->
        <ContadorOne v-if="flagContadorOne"
        :contador="contadorSelectedFromChild" 
        @btnBack="flagContadorOne = $event"
        />

    </div>
</template>

<script>
import PainelExpande from './PainelExpande'
import ContadorOne from './ContadorOne'

export default {

    //props:['flagContadorOneByMenu'],

    components:{PainelExpande,ContadorOne},

    data() {
        return {
            //
            qntClasses:0,
            qntContadores:0,
            contadorSelectedFromChild:{},
            flagContadorOne:false,

            // teste local. (store desde 05/09/2024)
            /*liContadores:[
                {
                    'classe':'Centro (Covilhã)',
                    'liContadores':[
                        {
                            'id':'c000001',
                            'nome':'Casa n12 rc',
                            'classe':'Centro (Covilhã)',
                            'lileituras':[
                                {
                                    'data':'05/06/2024',
                                    'leitura':'123280'
                                },
                                {
                                    'data':'05/07/2024',
                                    'leitura':'123000'
                                },
                                {
                                    'data':'05/08/2024',
                                    'leitura':'123320'
                                },
                                {
                                    'data':'05/09/2024',
                                    'leitura':'123295'
                                }
                            ],
                            'coord':[40.279184, -7.504404],
                        },
                        {
                            'id':'c000002',
                            'nome':'Casa n11 1D',
                            'classe':'Centro (Covilhã)',
                            'lileituras':[
                                {
                                    'data':'05/09/2024',
                                    'leitura':'123654'
                                }
                            ],
                        },
                    ],
                },
                {
                    'classe':'Biquinha (Covilhã)',
                    'liContadores':[
                        {
                            'id':'c000003',
                            'nome':'Casa n87 2E',
                            'classe':'Centro (Covilhã)',
                            'lileituras':[
                                {
                                    'data':'05/09/2024',
                                    'leitura':'123654'
                                }
                            ],
                        },
                        {
                            'id':'c000004',
                            'nome':'Casa n87 2D',
                            'classe':'Centro (Covilhã)',
                            'lileituras':[
                                {
                                    'data':'05/09/2024',
                                    'leitura':'123654'
                                }
                            ],
                        },
                        {
                            'id':'c000005',
                            'nome':'Casa n87 3E',
                            'classe':'Centro (Covilhã)',
                            'lileituras':[
                                {
                                    'data':'05/09/2024',
                                    'leitura':'123654'
                                }
                            ],
                        },
                    ],
                },
            ],*/
        }
    },

    methods:{
        //
        contadorSelected(contador) { 
            //console.log('contador selecionado: ',contador)
            this.contadorSelectedFromChild = contador
            this.flagContadorOne = true // abrir pagina individual por contador.
        },
    },

    created() {
        window.scrollTo(0,0)

        //this.flagContadorOne = this.flagContadorOneByMenu // prop computed ??
        
        this.qntClasses = this.$store.state.liContadores.length
        this.qntContadores = 0
        var soma=0
        var c=0
        while (c < this.qntClasses) {

            soma = soma + this.$store.state.liContadores[c].liContadores.length
            c++
        }
        this.qntContadores = soma
    }
}
</script>