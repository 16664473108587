<style scoped>
    @import url("../../styles/contadorAguaPage/inputLeitura.scss");
</style>

<template>
    <div class="box_inputLeitura">

        <div class="painel">

            <div class="session_form mb-2 mt-2" v-if="flag_loading_loginAgro">

                <span class="mb-2" :style="{'font-size':'0.7rem'}">Adicionando nova leitura</span>

                <v-progress-circular
                :size="70"
                :width="7"
                color="green"
                indeterminate
                :style="{'display':'flex','align-self':'center'}"
                ></v-progress-circular>

            </div>

            <v-icon>mdi-counter</v-icon>

            <div class="tit mt-4" v-if="!flag_loading_loginAgro" :style="{'font-weight':'bold'}">Insira a nova leitura</div>

            <v-form v-if="!flag_loading_loginAgro" class="session_form"
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-text-field
                color="orange"
                v-model="leitura"
                :rules="nameRules"
                label="Leitura contador"
                required
                ></v-text-field>

                <!--<v-text-field
                type="password"
                color="orange"
                v-model="passClient"
                label="Palavra chave"
                required
                ></v-text-field>-->
            </v-form>

            <div v-if="!flag_loading_loginAgro" class="btn_login">
                <v-btn
                small
                block
                :disabled="!valid"
                color="success"
                class="mr-4 mt-1"
                @click="chamarClientPage"
                >
                    Adicionar nova leitura
                </v-btn>
            </div>

            <span v-if="flagErrorLogin && !flag_loading_loginAgro"
            :style="{'margin-top':'10px','color':corMsgErro,'font-size':'0.8rem'}">
                {{ msgErrorLogin }}
            </span>

        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            //
            leitura:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorLogin:false,
            msgErrorLogin:'leitura inválida',
            corMsgErro:'red',

            valid: false, // btn 'entrar' inicia desabilitado.
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],

            flag_loading_loginAgro:false,
        }
    },

    methods:{
        //
        obterDate() {

            // get time
            var dataAtual = new Date();
            var dia = dataAtual.getDate();
            var mes = (dataAtual.getMonth() + 1);
            var ano = dataAtual.getFullYear();
            var horas = dataAtual.getHours();
            var minutos = dataAtual.getMinutes();

            if(String(minutos).length == 1) {

                minutos = ("0" + minutos)
            }
            if(String(horas).length == 1) {

                horas = ("0" + horas)
            }
            if(String(dia).length == 1) {

                dia = ("0" + dia)
            }
            if(String(mes).length == 1) {
                
                mes = ("0" + mes)
            }

            let day = (dia + "/" + mes + "/" + ano)
            //let hr = (horas + ":" + minutos) 

            /*var date = {
                day: day,
                hr: hr
            }
            
            return date*/
            return day
        },
        //
        chamarClientPage() {

            this.flag_loading_loginAgro = true // iniciar loading...

            if (this.leitura.length >= 2) { // teste qnt 06/09/2024

                var self = this

                if (this.$refs.form.validate()) {

                    //var payload = {'name':self.nameClient, 'pass':self.passClient }

                    /*axios.post(self.$store.state.pathAxiosLoginAgro, payload).then(function (res) {

                        //console.log(res.data.result)

                        if (res.data.result.ref == 'auth') {

                            self.$store.state.flagLogadoDashAgro = true
                            //console.log('[ok]')
                            self.flag_loading_loginAgro = false // desabilitar loading
                            
                            // -- STORAGE
                            var update_credenAgro = {'flagLogadoDashAgro':true}
                            localStorage.setItem('credenAgro', JSON.stringify(update_credenAgro))
                            return

                        } else {

                            self.flag_loading_loginAgro = false // desabilitar loading
                            self.flagErrorLogin = true
                            return
                        }
                    })*/
                    /*this.$store.state.liContadores.push({
                        'classe':this.nomeClasse,
                        'liContadores':[]
                    })*/

                    // ADD LOCAL e COMMIT DB

                    setTimeout(() =>{
                        var leitura2send = {'leitura':this.leitura,'dia':this.obterDate()}
                        this.$emit('envioLeitura',leitura2send)
                        
                        this.flag_loading_loginAgro = false // desabilitar loading
                        this.flagErrorLogin = true
                        this.leitura = ''
                        this.msgErrorLogin = 'Leitura adicionada.'
                        this.corMsgErro = 'green'
                        //console.log('teste leitura input')
                        return
                    },1000)

                } else {

                    self.flag_loading_loginAgro = false // desabilitar loading
                    self.flagErrorLogin = true
                    this.msgErrorLogin = 'Leitura inválida.'
                    this.corMsgErro = 'red'
                    return
                }

            } else {
                
                this.flag_loading_loginAgro = false // desabilitar loading
                this.flagErrorLogin = true
                this.msgErrorLogin = 'Leitura inválida.'
                this.corMsgErro = 'red'
                return
            }
        },
    },

    created() {
        window.scrollTo(0,0)
    }
}
</script>