<style scoped>
    @import url("../../styles/contadorAguaPage/searchContador.scss");
</style>

<template>
    <div class="box_searchContador mt-2 mb-2">

        <!-- TITULO -->
        <div class="tit" v-if="!flagContadorOne">
            <v-icon large :style="{'color':'rgb(31,122,177)'}">mdi-home-search</v-icon>
            <span class="txtTitSearch">Pesquisar contador</span>
        </div>

        <!-- BTNS -->
        <div class="lineBtnsChooseSearch" v-if="!flagContadorOne">
            <div 
            v-for="(btn,i) in liBtns" :key="i"
            class="btnChooseSearch"
            @mouseover="btn.backShadow = '0px 1px 2px rgb(247,215,46)'"
            @mouseleave="btn.backShadow = '0px 1px 2px rgb(31,122,177)'"
            :style="{'box-shadow':btn.backShadow}"
            @click="callBarSearch(i)"
            >{{ btn.nomeBtn }}</div>
        </div>

        <div class="titBusca mt-2" v-if="!flagContadorOne">
            <span>{{ tituloBusca }}</span>
        </div>

        <!-- BUSCADOR 0 (por classe) -->
        <div class="lineSearch mt-2" v-if="!flagContadorOne && flagSearchByClasse">
            <v-autocomplete
            v-model="classe_contadorSelecionado"
            :items="liSearchClasse"
            color="rgb(247,215,46)"
            :height="20"
            @change="callContador()"
            ></v-autocomplete>
        </div>

        <!-- BUSCADOR 1 (por nome do contador) -->
        <div class="lineSearch mt-2" v-if="!flagContadorOne && flagSearchByContadorName">
            <v-autocomplete
            v-model="nome_contadorSelecionado"
            :items="liSearchNome"
            color="rgb(247,215,46)"
            :height="20"
            @change="callContador()"
            ></v-autocomplete>
        </div>

        <!-- BUSCADOR 2 (por id do contador) -->
        <div class="lineSearch mt-2" v-if="!flagContadorOne && flagSearchByContadorId">
            <v-autocomplete
            v-model="id_contadorSelecionado"
            :items="liSearchId"
            color="rgb(247,215,46)"
            :height="20"
            @change="callContador()"
            ></v-autocomplete>
        </div>

        <!-- Exibição resultado da pesquisa -->
        <!-- Exibição de todos os contadores da classe selecionada -->
        <PainelExpande v-if="!flagContadorOne && flagSearchByClasse"
        @contadorSelected="contadorSelected($event)" 
        :liContadores="classe2Exib" 
        />

        <ContadorOne v-if="flagContadorOne"
        :contador="contadorSelectedFromChild" 
        @btnBack="flagContadorOne = $event"
        />

        <!-- v-for="(c,j) in item.liContadores" :key="j" -->
        <!-- Exibição dos contadores desconsiderando a sua exibição via classe -->
        <div class="contadorExib mt-4" 
        v-if="!flagContadorOne && (flagSearchByContadorName || flagSearchByContadorId) && this.flagExibContadorPrimaView"
        >
            <div @click="contadorSelected(contador2Exib)"
            class="boxContador" 
            >

                <div class="elCol_Box">
                    <span class="">ID</span>
                    <span :style="{'color':'rgb(247,215,46)'}">{{ contador2Exib.id }}</span>
                </div>
                <div class="elCol_Box">
                    <span class="">Nome</span>
                    <span :style="{'color':'rgb(247,215,46)'}">{{ contador2Exib.nome }}</span>
                </div>
                <div class="elCol_Box">
                    <span class="">Classe</span>
                    <span :style="{'color':'rgb(247,215,46)'}">{{ contador2Exib.classe }}</span>
                </div>
                <div class="elCol_Box">
                    <span class="">Última leitura</span>
                    <span v-if="contador2Exib.lileituras.length > 0"
                    :style="{'color':'rgb(247,215,46)'}"
                    >
                        {{ contador2Exib.lileituras[contador2Exib.lileituras.length-1].leitura }}
                    </span>
                    <span :style="{'color':'rgb(247,215,46)'}" v-else>Nenhuma leitura.</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import PainelExpande from './PainelExpande'
import ContadorOne from './ContadorOne'

export default {

    components:{PainelExpande,ContadorOne},

    data() {
        return {
            flagContadorOne:false, // disparada após um contador ser selecionado para exibição.
            flagExibContadorPrimaView:false,

            flagSearchByClasse:false,
            flagSearchByContadorName:false,
            flagSearchByContadorId:false,

            tituloBusca:'',

            liSearchClasse:[],
            liSearchNome:[],
            liSearchId:[],

            classe_contadorSelecionado:'',
            nome_contadorSelecionado:'',
            id_contadorSelecionado:'',

            classe2Exib:[], // classe a ser exibida resultado da pesquisa por classe.
            contador2Exib:[],

            liBtns:[
                {
                    'nomeBtn':'Buscar por classe',
                    'backShadow':'0px 1px 2px rgb(31,122,177)'
                },
                {
                    'nomeBtn':'Buscar por nome contador',
                    'backShadow':'0px 1px 2px rgb(31,122,177)'
                },
                {
                    'nomeBtn':'Buscar por id contador',
                    'backShadow':'0px 1px 2px rgb(31,122,177)'
                }
            ],
        }
    },

    methods:{
        // -- controle de página.
        contadorSelected(contador) { 
            //console.log('contador selecionado: ',contador)
            this.contadorSelectedFromChild = contador
            this.flagContadorOne = true // abrir pagina individual por contador.
        },
        //
        callContador() {
            //console.log('classe_contadorSelecionado: ',this.classe_contadorSelecionado)
            //console.log('nome_contadorSelecionado: ',this.nome_contadorSelecionado)
            //console.log('id_contadorSelecionado: ',this.id_contadorSelecionado)

            // -- busca por classe.
            if (this.classe_contadorSelecionado != '') {
                console.log('init if classe')

                var i=0
                while (i<this.$store.state.liContadores.length) {
                    if (this.$store.state.liContadores[i].classe == this.classe_contadorSelecionado) {
                        // -- classe encontrada. Printar todos os contadores pertencentes a ela.
                        this.classe2Exib = [this.$store.state.liContadores[i]]

                        // -- reset.
                        //this.classe_contadorSelecionado = ''
                        //this.nome_contadorSelecionado = ''
                        //this.id_contadorSelecionado = ''
                        this.flagExibContadorPrimaView = false
                        return
                    }
                    i++
                }
            }

            var j=0
            var k=0
            // -- busca por nome de contador.
            if (this.nome_contadorSelecionado != '') {
                console.log('init if nome')

                //console.log('this.$store.state.liContadores: ',this.$store.state.liContadores)

                j=0
                while (j<this.$store.state.liContadores.length) {
                    k=0
                    while (k<this.$store.state.liContadores[j].liContadores.length) {
                        //console.log('this.$store.state.liContadores[j].liContadores[k]: ',this.$store.state.liContadores[j].liContadores[k])
                        if (this.$store.state.liContadores[j].liContadores[k].nome == this.nome_contadorSelecionado) {
                            // -- contador encontrado pelo nome.
                            this.contador2Exib = this.$store.state.liContadores[j].liContadores[k]
                            this.flagExibContadorPrimaView = true
                            console.log('contador encontrado pelo nome')

                            // -- reset.
                            //this.classe_contadorSelecionado = ''
                            //this.nome_contadorSelecionado = ''
                            //this.id_contadorSelecionado = ''
                            return
                        }
                        k++
                    }
                    j++
                }
            }

            // -- busca por por id do contador.
            if (this.id_contadorSelecionado != '') {
                console.log('init if id')

                j=0
                while (j<this.$store.state.liContadores.length) {
                    k=0
                    while (k<this.$store.state.liContadores[j].liContadores.length) {

                        if (this.$store.state.liContadores[j].liContadores[k].id == this.id_contadorSelecionado) {
                            // -- contador encontrado pelo if.
                            this.contador2Exib = this.$store.state.liContadores[j].liContadores[k]
                            this.flagExibContadorPrimaView = true
                            console.log('contador encontrado pelo id')

                            // -- reset.
                            //this.classe_contadorSelecionado = ''
                            //this.nome_contadorSelecionado = ''
                            //this.id_contadorSelecionado = ''
                            return
                        }
                        k++
                    }
                    j++
                }
            }
        },
        // SWITCH
        callBarSearch(i) {
            //console.log('i: ',i)

            // -- reset.
            this.classe_contadorSelecionado = ''
            this.nome_contadorSelecionado = ''
            this.id_contadorSelecionado = ''

            if (i==0) { 
                this.flagSearchByClasse = true
                this.flagSearchByContadorName = false
                this.flagSearchByContadorId = false
                this.tituloBusca = this.liBtns[0].nomeBtn
            }
            if (i==1) {
                this.flagSearchByClasse = false
                this.flagSearchByContadorName = true
                this.flagSearchByContadorId = false
                this.tituloBusca = this.liBtns[1].nomeBtn
            }
            if (i==2) {
                this.flagSearchByClasse = false
                this.flagSearchByContadorName = false
                this.flagSearchByContadorId = true
                this.tituloBusca = this.liBtns[2].nomeBtn
            }
        }
    },

    created() {
        //window.scrollTo(0,0)
        var i=0
        while (i<this.$store.state.liContadores.length) {
            this.liSearchClasse.push(this.$store.state.liContadores[i].classe)
            var j=0
            while (j<this.$store.state.liContadores[i].liContadores.length) {
                this.liSearchNome.push(this.$store.state.liContadores[i].liContadores[j].nome)
                this.liSearchId.push(this.$store.state.liContadores[i].liContadores[j].id)
                j++
            }
            i++
        }
        //console.log('created ok')
    }
}
</script>