<style scoped>
    @import url("../../styles/contadorAguaPage/contadorOne.scss");
</style>

<template>
    <div class="box_contadorOne">

        <!-- Título do contador -->
        <div class="titContador">
            <v-icon large :style="{'color':'rgb(31,122,177)'}">mdi-water-pump</v-icon>
            <span :style="{'font-size':'1.4rem'}">{{ contador.nome }}, {{ contador.classe }}</span>
        </div>

        <div class="btnBack mt-2" @click="backToMain()"
        @mouseover="hoverBackInit = 'rgb(31,122,177)'"
        @mouseleave="hoverBackInit = 'rgb(247,215,46)'"
        >
            <v-icon large :style="{'color':hoverBackInit}">mdi-arrow-left-thick</v-icon>
            <span>Voltar</span>
        </div>

        <!-- Painel input leitura contador -->
        <div class="painelInputLeitura mt-2">
            
            <span class="" 
            :style="{'font-size':'1.2rem','color':'rgb(31,122,177)','align-self':'center'}">Painel de leituras</span>
            
            <div class="boxLeitura">
                <LeituraInput @envioLeitura="inputLeitura($event)" />
            </div>
        </div>

        <!-- Gráfico -->
        <div class="histContador mt-2">
            <apexchart ref="chart" :width="widthMapDinam" :options="options" :series="series"></apexchart>
        </div>

        <!-- Mapa -->
        <div class="mapaContador mt-2">
            <div class="box_mapa" :style="{'min-width':widthMapDinam}" id="mapaid"></div>
            <div class="auxBarra" :style="{'min-width':widthMapDinam}"></div>
        </div>
        <!--<div class="mapaContador mt-2" id="mapaid"></div>-->
        <!--<MapaContador />-->

    </div>
</template>

<script>
import LeituraInput from './LeituraInput'
import L from "leaflet"; // npm install --save leaflet

export default {

    props:['contador'],

    components:{LeituraInput},

    data() {
        return {
            //
            hoverBackInit:'rgb(247,215,46)',

            //
            widthMapDinam:'280px',

            // init.
            options: {
                /*chart: {
                    id: 'mapaContador'
                },
                xaxis: {
                    categories: [1,1,1,1,1,1]
                }*/
                chart: {
                    id: 'mapaContador',
                    toolbar: {
                        show: false  // Desativa o menu (toolbar)
                    }
                },
                labels: ['', '', '', '', '', ''],
            },
            series: [{
                name: 'Contador',
                type: 'bar',
                data: [0, 1, 2, 1.5, 0.2, 0.9]
            }]
        }
    },

    methods:{
        //
        backToMain() { this.$emit('btnBack',false) },

        inputLeitura(leitura) {
            //
            //this.options.xaxis.categories.push(leitura.dia) // lista com o eixo x. (formato dia/mes/ano)
            this.series[0].data.push(leitura.leitura) // lista com o valores.

            this.$refs.chart.updateSeries(this.series, true) // forçar chart update.

            // atualizar db local e posteriormente db geral.
            // var contador é rcb via props.
            //console.log('this.contador[id].length-1',this.contador['id'].length-1)
            //console.log('this.contador[id].length-1',this.contador['id'].length)
            var idContador = this.contador['id'].slice(this.contador['id'].length-1,this.contador['id'].length)
            idContador = parseInt(idContador)
            let c = 0
            while (c<this.$store.state.liContadores.length) {
                if (this.$store.state.liContadores[c]['classe'] == this.contador['classe']) {
                    // se encontrou a classe de contador, buscar o contador a ser atualizado.
                    //console.log('contador: ',this.$store.state.liContadores[c]['liContadores'][idContador-1])
                    //leitura.dia = this.obterDate()
                    //console.log('this.obterDate()',this.obterDate())
                    //console.log('leitura.dia',leitura)
                    leitura.leitura = parseFloat(leitura.leitura)
                    this.$store.state.liContadores[c]['liContadores'][idContador-1]['lileituras'].push(leitura)
                    
                    this.contador.lileituras.push(leitura)
                    // aqui o grafico não é atualizado com o ocorrido após linha 89.
                }
                c++
            }
            console.log('this.$store.state.liContadores: ',this.$store.state.liContadores)
        },
    },

    mounted() {
        var map = L.map('mapaid').setView([this.contador.coord[0],this.contador.coord[1]], 11);

        //console.log('map devices start')

        L.marker([this.contador.coord[0],this.contador.coord[1]]).addTo(map)
        .bindPopup(`${this.contador.nome}, ${this.contador.classe}`)
        .openPopup();
        
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    },

    created() {
        window.scrollTo(0,0)

        // -- largura para o tamanho dos elementos gráfico e mapa.
        var largura = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

        this.widthMapDinam = 0.72 * largura
        this.widthMapDinam = `${this.widthMapDinam}px`
        //console.log('this.widthMapDinam: ',this.widthMapDinam)

        //console.log('this.$store.state.liContadores: ',this.$store.state.liContadores)
        //console.log('this.contador: ',this.contador)

        // -- contador rcv via props
        // ---> e se o contador via props estiver vazio? até então ok. 07/10/2024.
        // --> problema no formato da string do eixo x dates do gráfico contador.
        //console.log('this.contador.lileituras: ',this.contador.lileituras.length)
        var c=0
        //var liDates = []
        var liValues = []
        while (c < this.contador.lileituras.length-1) {
            //liDates.push(this.contador.lileituras[c].data)
            liValues.push(this.contador.lileituras[c].leitura)
            c++
        }
        //this.options.xaxis.categories = liDates // lista com o eixo x.
        this.series[0].data = liValues // lista com o valores.
    }
}
</script>