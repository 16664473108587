<style scoped>
    @import url("../../styles/contadorAguaPage/addClasse.scss");
</style>

<template>
    <div class="box_addClasse">

        <div class="painelNovoClasse">

            <div class="session_form mb-2 mt-2" v-if="flag_loading_loginAgro">

                <span class="mb-2" :style="{'font-size':'0.7rem'}">Adicionando nova classe</span>

                <v-progress-circular
                :size="70"
                :width="7"
                color="green"
                indeterminate
                :style="{'display':'flex','align-self':'center'}"
                ></v-progress-circular>

            </div>

            <v-icon>mdi-home-city</v-icon>

            <div class="tit mt-4" v-if="!flag_loading_loginAgro" :style="{'font-weight':'bold'}">Nova classe</div>

            <v-form v-if="!flag_loading_loginAgro" class="session_form"
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-text-field
                color="orange"
                v-model="nomeClasse"
                :rules="nameRules"
                label="Nome classe"
                required
                ></v-text-field>

                <!--<v-text-field
                type="password"
                color="orange"
                v-model="passClient"
                label="Palavra chave"
                required
                ></v-text-field>-->
            </v-form>

            <div v-if="!flag_loading_loginAgro" class="btn_login">
                <v-btn
                small
                block
                :disabled="!valid"
                color="success"
                class="mr-4 mt-1"
                @click="chamarClientPage"
                >
                    Adicionar classe
                </v-btn>
            </div>

            <span v-if="flagErrorLogin && !flag_loading_loginAgro"
            :style="{'margin-top':'10px','color':corMsgErro,'font-size':'0.8rem'}">
                {{ msgErrorLogin }}
            </span>

        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            //
            nomeClasse:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorLogin:false,
            msgErrorLogin:'Nome inválido',
            corMsgErro:'red',

            valid: false, // btn 'entrar' inicia desabilitado.
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],

            flag_loading_loginAgro:false,
        }
    },

    methods:{
        //
        chamarClientPage() {

            this.flag_loading_loginAgro = true // iniciar loading...

            if (this.nomeClasse.length >= 4) {

                var self = this

                if (this.$refs.form.validate()) {

                    //var payload = {'name':self.nameClient, 'pass':self.passClient }

                    /*axios.post(self.$store.state.pathAxiosLoginAgro, payload).then(function (res) {

                        //console.log(res.data.result)

                        if (res.data.result.ref == 'auth') {

                            self.$store.state.flagLogadoDashAgro = true
                            //console.log('[ok]')
                            self.flag_loading_loginAgro = false // desabilitar loading
                            
                            // -- STORAGE
                            var update_credenAgro = {'flagLogadoDashAgro':true}
                            localStorage.setItem('credenAgro', JSON.stringify(update_credenAgro))
                            return

                        } else {

                            self.flag_loading_loginAgro = false // desabilitar loading
                            self.flagErrorLogin = true
                            return
                        }
                    })*/
                    this.$store.state.liContadores.push({
                        'classe':this.nomeClasse,
                        'liContadores':[]
                    })

                    setTimeout(() =>{
                        console.log('classe adicionada com sucesso.')
                        self.flag_loading_loginAgro = false // desabilitar loading
                        //self.flagErrorLogin = false // reset
                        self.flagErrorLogin = true // habilitar msg de confirmação.
                        this.nomeClasse = ''
                        this.msgErrorLogin = 'Classe adicionada.'
                        this.corMsgErro = 'green'
                        return
                    },1000)

                } else {

                    self.flag_loading_loginAgro = false // desabilitar loading
                    self.flagErrorLogin = true
                    return
                }

            } else {
                
                this.flag_loading_loginAgro = false // desabilitar loading
                this.flagErrorLogin = true
                return
            }
        },
    },

    created() {
        window.scrollTo(0,0)
    }
}
</script>