import Vue from 'vue'
import VueRouter from 'vue-router'

//import Main from '../components/main/Main.vue'
import MainV2 from '../components/mainV2/MainV2.vue'

import Automation from '../components/automation/Automation.vue'

import QuemSomos from '../components/quemSomos/QuemSomos.vue'

import DashMain from '../components/dashMain/DashMain.vue'

import MakersMain from '../components/makers/MakersMain.vue'

import Loja from '../components/loja/Loja.vue'

import Agro from '../components/agro/Agro.vue'
import DashAgro from '../components/dashAgro/DashAgro.vue'

// Smart Farm (18/09/23)
import FazendasDash from '../components/dashAgro/smartFarm/FazendasDash.vue'
import Fazenda from '../components/dashAgro/smartFarm/Fazenda.vue' // Smart Farm (19/09/23)
import ParamFarm from '../components/dashAgro/smartFarm/ParamFarm.vue' // Smart Farm (19/09/23)

//import Lora from '../components/lora/Lora.vue' // Pág LoRa (11/07/24)
import PageLora from '../components/lora/PageLora.vue' // Pág LoRa (20/08/24)

import ContadorAguaPage from '../components/contadorAguaPage/ContadorAguaPage.vue' // (04/09/24)

import GatewayPageComercial from '../components/gateway/GatewayPageComercial.vue' // (12/09/24)

import MonitorComercial from '../components/monitor/MonitorComercial.vue' // (12/09/24)

import MainExibServ from '../components/exibServ/MainExibServ.vue' // 24/09/2024, 03/10/2024

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/',
    name: 'Main',
    component: Main
  },*/
  {
    path: '/',
    name: 'MainV2',
    component: MainV2
  },
  {
    path: '/quemSomos',
    name: 'QuemSomos',
    component: QuemSomos
  },
  {
    path: '/dashmain',
    name: 'DashMain',
    component: DashMain
  },
  {
    path: '/ca',
    name: 'ContadorAguaPage',
    component: ContadorAguaPage
  },
  {
    path: '/gatewaycomercial',
    name: 'GatewayPageComercial',
    component: GatewayPageComercial
  },
  {
    path: '/monitorc',
    name: 'MonitorComercial',
    component: MonitorComercial
  },
  {
    path: '/makers',
    name: 'MakersMain',
    component: MakersMain
  },
  {
    path: '/automation',
    name: 'Automation',
    component: Automation
  },
  {
    path: '/loja',
    name: 'Loja',
    component: Loja
  },
  {
    path: '/agro',
    name: 'Agro',
    component: Agro
  },
  {
    path: '/dashagro',
    name: 'DashAgro',
    component: DashAgro
  },
  {
    path: '/smartfarm',
    name: 'FazendasDash',
    component: FazendasDash
  },
  {
    path: '/land',
    name: 'Fazenda',
    component: Fazenda
  },
  {
    path: '/param',
    name: 'ParamFarm',
    component: ParamFarm
  },
  {
    path: '/lora',
    name: 'PageLora',
    component: PageLora
  },
  {
    path:'/exib',
    name:'MainExibServ',
    component: MainExibServ
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* Antes de cada navegação a função é chamada */
import store from '@/store/index.js'
//import { component } from 'vue/types/umd.js'
router.beforeEach((to, from , next) => {

  //console.log('Em rota...')
  store.commit('init') // despertar api.

  if(to.path == '/') {

    // -- Quando main for chamado -> Então esconder menu superior (priorizando a exib. página main). 
    store.state.flagMenuSup = false
  }
  /*else{

    store.state.authForShow = false // se não estiver => auth blocked
  }

  //console.log('Estou na home:', store.state.authForShow)
  
  store.commit('updateCartList') // sem param
  */

  next() // sem a chamada next() a rota é travada.

})

export default router
