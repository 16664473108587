import { render, staticRenderFns } from "./Fazenda.vue?vue&type=template&id=89101364&scoped=true&"
import script from "./Fazenda.vue?vue&type=script&lang=js&"
export * from "./Fazenda.vue?vue&type=script&lang=js&"
import style0 from "./Fazenda.vue?vue&type=style&index=0&id=89101364&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89101364",
  null
  
)

export default component.exports