<style scoped>
    @import url("../../styles/contadorAguaPage/contadorAguaPage.scss");
</style>

<template>
    <div class="box_contadorAguaPage" v-if="!$store.state.flagMenuSup"
    :style="{
    backgroundImage:'url(' + require('../../assets/canot0.webp') + ')',
    backgroundSize: 'cover', // contain cover
    backgroundPosition: 'bottom' // center
    }"
    >

        <div v-if="flagLoadingCA"
        :style="{'display':'flex','width':'100%','align-items':'center','justify-content':'center'}">
            <v-progress-circular 
                color="rgb(247,215,46)"
                size="64"
                indeterminate
            ></v-progress-circular>
        </div>

        <!-- MENU LATERAL -->
        <MenuLateralDash v-if="!flagLoadingCA"
        @watchExib="callChangeFlagExib($event)" 
        @watchHideMenu="callChangeTamExib($event)" 
        @chooseItemFromMenu="chooseItemFromMenu($event)"
        />

        <!-- PAINEL EXIBIDOR -->
        <div class="painelExibidor" :style="{'width':widthDinam}" v-if="flagExib && !flagLoadingCA">

            <VerTodos v-if="strFlagMenuOpenItem == 'Ver todos'" />

            <AddClasse v-if="strFlagMenuOpenItem == 'Adicionar nova classe'" />

            <AddContador v-if="strFlagMenuOpenItem == 'Adicionar novo contador'" />

            <SearchContador v-if="strFlagMenuOpenItem == 'Pesquisar contador'" />

        </div>

    </div>
</template>

<script>
import axios from 'axios'

import MenuLateralDash from './MenuLateralDash'

import VerTodos from './VerTodos'
import AddClasse from './AddClasse'
import AddContador from './AddContador'
import SearchContador from './SearchContador'

export default {

    components:{MenuLateralDash,VerTodos,AddClasse,AddContador,SearchContador},

    data() {
        return {
            //
            flagLoadingCA:true, // desabilitar Loading depois de carregar as info da page.
            //
            flagExib:true, // init versão large
            widthDinam:'75%', // init versão large com menu aberto. Na versão small o menu é width=100% sempre.

            //
            strFlagMenuOpenItem:'Ver todos', // controle do item selecionado na página, pelo menu lateral.

            //
            LarguraPage:0,
        }
    },

    methods:{
        // ---- CONTROLE DO ENQUADRAMENTO DO TAMANHO DA PÁGINA.
        // -- controle da versão do tamanho da página.
        callChangeFlagExib(flagFromChild) { this.flagExib = flagFromChild }, //flagFromChild[0] },
        // ---- CONTROLE DO ENQUADRAMENTO DO TAMANHO DA PÁGINA.
        // -- se o menu fechou então aumentar tamanho do painel de exibição da página, pois o menu ficou menor.
        callChangeTamExib(flagHide) { 
            //var flagHide = li[0]
            //var tamPage = li[1]
            if (flagHide == true) { // menu fechou.

                if (this.LarguraPage < 500) { this.flagExib = true }

                this.widthDinam = '100%' // preencher toda a página, pois o menu lateral é 80px.

            } else { // menu abriu.

                // versão small deve ser exibida quando o menu fechar.
                if (this.LarguraPage < 500) { this.flagExib = false }

                this.widthDinam = '75%' // reset (menu re-aberto).
            }
        },

        // ---- SWITCH para selecionar o item do menu, emitido do componente child.
        chooseItemFromMenu(strFromMenu) { 

            if (strFromMenu == 'Voltar Área Usuário') { this.$router.push('/dashmain'); return; }

            this.strFlagMenuOpenItem = strFromMenu
            this.flagExib = true // exibir conteúdo selecionado pelo menu lateral.
        },
    },

    created() {
        window.scrollTo(0,0)

        // -- STORAGE
        var creden = JSON.parse(localStorage.getItem('creden'))
        var contentUser = JSON.parse(localStorage.getItem('contentUser'))

        if (creden != null) {
            this.$store.state.flagLogadoDash = creden.flagLogadoDash
            this.$store.state.contentUser = contentUser
        }

        if (this.$store.state.flagLogadoDash == true) { // última alteração 07/10/2024

            this.LarguraPage = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

            var self = this

            // desabilitar overlay após requisição concluída.
            axios.get(self.$store.state.pathAxiosGetCA).then(function (res) {

                //console.log(res.data.result)

                // -- VERSÃO DE RECEBIMENTO PARA APENAS 1 CONTADOR.
                let c=0
                var liAux = []
                while (c<res.data.result.length) {
                    liAux.push({
                        'data':res.data.result[c].dataRcv.slice(0, 10),
                        'leitura':res.data.result[c].litrosConsumidos
                    })
                    c++
                }
                self.$store.state.liContadores = [
                    {
                        'classe':'Proj. UPinC Contadores LoRaWAN',
                        'liContadores':[
                            {
                                'id':'c1',
                                'nome':'UPinC Exemplo',
                                'classe':'Proj. UPinC Contadores LoRaWAN',
                                'coord':[40.279184, -7.504404],
                                'lileituras':liAux
                            }
                        ],
                    },
                ]

                //liContadores

                self.flagLoadingCA = false

                //self.$store.state.farm_li_names = res.data.result.names
                
                //self.$store.state.flag_loading_init_farm = false // desabilitar loading. Abrir page.
            })

        } else {

            // -- reencaminhar para login.
            this.$router.push('/dashmain')
        }
    }
}
</script>