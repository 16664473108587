<style scoped>
    @import url("../../styles/contadorAguaPage/addContador.scss");
</style>

<template>
    <div class="box_addContador">

        <div class="painelNovoContador mt-2 mb-2">

            <div class="session_form mb-2 mt-2" v-if="flag_loading_loginAgro">

                <span class="mb-2" :style="{'font-size':'0.7rem'}">Adicionando novo contador</span>

                <v-progress-circular
                :size="70"
                :width="7"
                color="green"
                indeterminate
                :style="{'display':'flex','align-self':'center'}"
                ></v-progress-circular>

            </div>

            <v-icon>mdi-water-plus</v-icon>

            <div class="tit mt-4" v-if="!flag_loading_loginAgro" :style="{'font-weight':'bold'}">Novo contador</div>

            <v-form v-if="!flag_loading_loginAgro" class="session_form"
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-text-field
                color="orange"
                v-model="nomeContador"
                :rules="nameRules"
                label="Nome contador"
                required
                ></v-text-field>

                <!-- ,'height':'50px' height="50"  -->
                <v-select v-if="!flagNovaClasse"
                v-model="nomeClasse"
                :items="liClassesExistentes"
                label="Selecione uma classe"
                outlined
                item-height="50"
                class="cardSelect"
                :rules="nameRules"
                ></v-select>

                <v-btn v-if="!flagNovaClasse"
                small
                block
                :disabled="!valid"
                color="blue"
                class="mt-1"
                @click="flagNovaClasse = true"
                >
                    Criar nova classe
                </v-btn>

                <!-- type="password" -->
                <v-text-field v-if="flagNovaClasse"
                color="orange"
                v-model="nomeClasse"
                label="Nova classe"
                required
                :rules="nameRules"
                ></v-text-field>

                <span
                class="mt-4"
                :style="{'font-size':'0.8rem'}"
                >Adicionar coordenada decimal</span>

                <v-text-field
                color="orange"
                v-model="coordLatitude"
                label="Latitude"
                required
                ></v-text-field>

                <v-text-field
                color="orange"
                v-model="coordLongitude"
                label="Longitude"
                required
                ></v-text-field>

                <!--<v-text-field
                color="orange"
                v-model="primeiraLeitura"
                label="Primeira leitura (opcional)"
                ></v-text-field>-->
            </v-form>

            <div v-if="!flag_loading_loginAgro" class="btn_login">
                <v-btn
                small
                block
                :disabled="!valid"
                color="success"
                class="mr-4 mt-1"
                @click="chamarClientPage"
                >
                    Adicionar contador
                </v-btn>
            </div>

            <span v-if="flagErrorLogin && !flag_loading_loginAgro"
            :style="{'margin-top':'10px','color':corMsgErro,'font-size':'0.8rem'}">
                {{ msgErrorLogin }}
            </span>

        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            //
            nomeContador:'',
            nomeClasse:'',
            liClassesExistentes:[], // usado no created().
            flagNovaClasse:false, // init com as classes existentes, se houverem.
            //primeiraLeitura:'',
            coordLatitude:'',
            coordLongitude:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorLogin:false,
            msgErrorLogin:'Informações inválidas',
            corMsgErro:'red',

            valid: false, // btn 'entrar' inicia desabilitado.
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],

            flag_loading_loginAgro:false,
        }
    },

    methods:{
        //
        chamarClientPage() {

            this.flag_loading_loginAgro = true // iniciar loading...

            if (this.nomeClasse.length >= 4 && this.nomeClasse.length >= 4) {

                //var self = this

                if (this.$refs.form.validate()) {

                    //var payload = {'name':self.nameClient, 'pass':self.passClient }

                    /*axios.post(self.$store.state.pathAxiosLoginAgro, payload).then(function (res) {

                        //console.log(res.data.result)

                        if (res.data.result.ref == 'auth') {

                            self.$store.state.flagLogadoDashAgro = true
                            //console.log('[ok]')
                            self.flag_loading_loginAgro = false // desabilitar loading
                            
                            // -- STORAGE
                            var update_credenAgro = {'flagLogadoDashAgro':true}
                            localStorage.setItem('credenAgro', JSON.stringify(update_credenAgro))
                            return

                        } else {

                            self.flag_loading_loginAgro = false // desabilitar loading
                            self.flagErrorLogin = true
                            return
                        }
                    })*/
                    
                    var qntContadores=0
                    var i=0
                    var novoStrIdContador = 0
                    // -- varrer classes. Sse foi criada nova classe.
                    if (this.flagNovaClasse) {
                        while (i<this.$store.state.liContadores.length) {
                            qntContadores = qntContadores + this.$store.state.liContadores[i].liContadores.length
                            // -- Se já existir a classe retornar msg de erro.
                            //console.log('this.$store.state.liContadores[i].classe: ',this.$store.state.liContadores[i].classe)
                            if (this.nomeClasse == this.$store.state.liContadores[i].classe) {
                                //console.log('classe encontarda')
                                this.flag_loading_loginAgro = false // desabilitar loading
                                this.flagErrorLogin = true // habilitar msg error.
                                this.msgErrorLogin = 'A classe já existe. Escolha outro nome.'
                                this.corMsgErro = 'red'
                                this.nomeClasse = ''
                                this.nomeContador = ''
                                return // se não houver ainda a classe então a nova classe está permitida.
                            }
                            i++
                        }

                        novoStrIdContador = `c${qntContadores+1}` // +1 => desconsidera o índice 0.

                        // primeiro contador dessa classe.
                        this.$store.state.liContadores.push(
                            {
                                'classe':this.nomeClasse,
                                'liContadores':[
                                    {
                                        'id':novoStrIdContador,
                                        'nome':this.nomeContador,
                                        'classe':this.nomeClasse,
                                        'coord':[this.coordLatitude, this.coordLongitude],
                                        'lileituras':[], // a leitura é automática.
                                    }
                                ]
                            }
                        )
                    }
                    //console.log('this.$store.state.liContadores: ',this.$store.state.liContadores)

                    // -- Se é um novo contador sendo inserido em uma classe já existente.
                    if (!this.flagNovaClasse) {
                        //var qntContadores=0
                        //var i=0
                        while (i<this.$store.state.liContadores.length) {
                            qntContadores = qntContadores + this.$store.state.liContadores[i].liContadores.length
                            // -- Se já existir a classe retornar msg de erro.
                            if (this.nomeClasse == this.$store.state.liContadores[i].classe) {
                                // -- após classe encontrada, obtido o id da classe, varrer os contadores.
                                var idClasse = i
                            }
                            i++
                        }
                        
                        novoStrIdContador = `c${qntContadores+1}` // +1 => desconsidera o índice 0.

                        this.$store.state.liContadores[idClasse].liContadores.push({
                            'id':novoStrIdContador,
                            'nome':this.nomeContador,
                            'classe':this.nomeClasse,
                            'coord':[this.coordLatitude, this.coordLongitude],
                            'lileituras':[], // a leitura é automática.
                        })
                    }

                    setTimeout(() =>{
                        //console.log('contador adicionada com sucesso.')
                        this.flag_loading_loginAgro = false // desabilitar loading
                        this.flagErrorLogin = true // habilitar msg error.
                        this.nomeClasse = ''
                        this.nomeContador = ''
                        this.msgErrorLogin = 'Contador adicionado.'
                        this.corMsgErro = 'green'
                        return
                    },1000)

                } else {

                    self.flag_loading_loginAgro = false // desabilitar loading
                    self.flagErrorLogin = true
                    return
                }

            } else {
                
                this.flag_loading_loginAgro = false // desabilitar loading
                this.flagErrorLogin = true
                return
            }
        },
    },

    created() {
        window.scrollTo(0,0)

        var c=0
        while (c < this.$store.state.liContadores.length) {
            this.liClassesExistentes.push(this.$store.state.liContadores[c].classe)
            c++
        }
        //console.log('lista de classes: ',this.liClassesExistentes)
    }
}
</script>